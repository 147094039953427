<template>
	<div class="sur">
		<div class="nav_img">
			<Swiper>
				<Slide>
					<img class="nav_pic" src="../assets/hot1.png" />
				</Slide>
				<Slide>
					<img class="nav_pic" src="../assets/hot2.png" />
				</Slide>
				<Slide>
					<img class="nav_pic" src="../assets/hot3.png" />
				</Slide>
				<Slide>
					<img class="nav_pic" src="../assets/hot4.png" />
				</Slide>
			</Swiper>
		</div>
		<div class="pro">
			<img src="../assets/sur/bg.jpg" class="bg" />
			<div class="content">
				<div class="pro_title">
					跑跑太空熊品牌周边产品
				</div>
				<div class="pro_title_en">
					GO GO BEAR PERIPHERAL PRODUCTS
				</div>
				<div class="box">
					<div class="box_left">
						<div :class="index == 1?'ac':'nomal'" @click="change(1)">杯子</div>
						<div :class="index == 2?'ac':'nomal'" @click="change(2)">匙扣</div>
						<div :class="index == 3?'ac':'nomal'" @click="change(3)">T恤</div>
						<div :class="index == 4?'ac':'nomal'" @click="change(4)">本子</div>
						<div :class="index == 5?'ac':'nomal'" @click="change(5)">笔</div>
					</div>
					<div class="box_right" v-if="index == 1">
						<div class="flex_row mt5">
							<div class="item">
								<img src="../assets/goods/cup1.jpg" class="pro_img" />
							</div>
							<div class="item">
								<img src="../assets/goods/cup2.jpg" class="pro_img" />
							</div>
						</div>
						<div class="flex_row mt6">
							<div class="item">
								<img src="../assets/goods/cup3.jpg" class="pro_img" />
							</div>
							<div class="item">
								<img src="../assets/goods/cup4.jpg" class="pro_img" />
							</div>
						</div>
					</div>
					<div class="box_right" v-if="index == 2">
						<div class="flex_row mt5">
							<div class="item">
								<img src="../assets/goods/k1.jpg" class="pro_img" />
							</div>
							<div class="item">
								<img src="../assets/goods/k2.jpg" class="pro_img" />
							</div>
						</div>
						<div class="flex_row mt6">
							<div class="item">
								<img src="../assets/goods/k3.jpg" class="pro_img" />
							</div>
							<div class="item">
								<img src="../assets/goods/k4.jpg" class="pro_img" />
							</div>
						</div>
					</div>
					<div class="box_right" v-if="index == 3">
						<div class="flex_row mt5">
							<div class="item">
								<img src="../assets/goods/cl1.jpg" class="pro_img" />
							</div>
							<div class="item">
								<img src="../assets/goods/cl2.jpg" class="pro_img" />
							</div>
						</div>
						<div class="flex_row mt6">
							<div class="item">
								<img src="../assets/goods/cl3.jpg" class="pro_img" />
							</div>
							<div class="item">
								<img src="../assets/goods/cl4.jpg" class="pro_img" />
							</div>
						</div>
					</div>
					<div class="box_right" v-if="index == 4">
						<div class="flex_row mt5">
							<div class="item">
								<img src="../assets/goods/b1.jpg" class="pro_img"/>
							</div>
							<div class="item">
								<img src="../assets/goods/b2.jpg" class="pro_img"/>
							</div>
						</div>
						<div class="flex_row mt6">
							<div class="item">
								<img src="../assets/goods/b3.jpg" class="pro_img"/>
							</div>
							<div class="item">
								<img src="../assets/goods/b4.jpg" class="pro_img"/>
							</div>
						</div>
					</div>
					<div class="box_right" v-if="index == 5">
						<div class="flex_row mt5">
							<div class="item">
								<img src="../assets/goods/p1.jpg" class="pro_img" />
							</div>
							<div class="item">
								<img src="../assets/goods/p2.jpg" class="pro_img" />
							</div>
						</div>
						<div class="flex_row mt6">
							<div class="item">
								<img src="../assets/goods/p3.jpg" class="pro_img" />
							</div>
							<div class="item">
								<img src="../assets/goods/p4.jpg" class="pro_img" />
							</div>
						</div>
					</div>
				</div>
				<div class="scan">
					<img src="../assets/sur/s_icon.png" class="scan_icon" />
					<div class="scan_title">扫小程序二维码可下单哦</div>
					<div class="scan_title_en">SCAN SMALL PROGRAM QR CODE CAN BE ORDERED OH</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import { Swiper, Slide } from 'vue-swiper-component';
	export default{
		name: 'surrounding',
		components: {
			Swiper,
		  Slide
		},
		data(){
			return {
				index: 1
			}
		},
		methods:{
			change(i){
				console.log(i)
				this.index = i
			}
		}
	}
</script>

<style scoped="scoped">
	.nav_img{
		width: 100%;
		/* height: 3rem; */
		
	}
	.nav_pic{
		width: 100%;
	}
	.bg{
		width: 100%;
	}
	.pro{
		width: 100%;
		position: relative;
	}
	.content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0.3rem 0.35rem;
	}
	.pro_title{
		font-size: 0.26rem;
		font-weight: bold;
		color: #fffa5a;
	}
	.pro_title_en{
		font-size: 0.15rem;
		color: #d4e6ff;
	}
	.pro_title_en::after{
		content: '';
		display: block;
		width: 0.3rem;
		height: 1px;
		margin: 0 auto;
		margin-top: 10px;
		background-color: #d4e6ff;
	}
	.box{
		position: absolute;
		top: 1.1rem;
		left: 0.25rem;
		width: 3.1rem;
		height: 1.95rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.box_left{
		width: 0.4rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
	.nomal{
		width: 0.37rem;
		height: 0.37rem;
		line-height: 0.37rem;
		border-radius: 50%;
		background-color: #d7d7f7;
		color: #fff;
		font-size: 0.12rem;
	}
	.ac{
		width: 0.37rem;
		height: 0.37rem;
		line-height: 0.35rem;
		border-radius: 50%;
		background-color: #c455ff;
		color: #fff;
		font-size: 0.12rem;
		border: 0.02rem solid #e5b3ff;
	}
	.box_right{
		width: 2.6rem;
		height: 100%;
	}
	.flex_row{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 49%;
	}
	.pro_img{
		width: 100%;
		height: 100%;
		border-radius: 0.1rem;
		margin: 0 auto;
		background-color: #fff;
	}
	.mt5{
		margin-top: 0.05rem;
	}
	.mt6{
		margin-top: 0.06rem;
	}
	.scan{
		margin-top: 2.35rem;
	}
	.scan_icon{
		width: 0.7rem;
	}
	.scan_title{
		font-size: 0.13rem;
		color: #fffa5a;
	}
	.scan_title_en{
		font-size: 0.12rem;
		color: #d4e6ff;
	}
	.item{
		background-color: #fff;
		width: 48%;
		height: 100%;
		border-radius: 0.1rem;
		overflow: hidden;
		box-shadow: 0.02rem 0.02rem 0.08rem #333;
	}
</style>

<template>
	<div class="Privacy">
		<div style="width:95%;margin:0 auto">
		<h3 style="text-align:center">
		GoGoBear (跑跑太空熊)用户隐私政策
		</h3>
		<p><SMALL>
		更新时间：2020年10月01日
		<br>
		生效时间：2020年11月09日
		</SMALL></p>
		
		<h3>
		关于我们
		</h3>
		
		
		
		
		<p>
		GoGoBear (跑跑太空熊)网站及APP的经营者为上海马尼亚娱乐咨询管理有限公司。
		</p>
		<p>
		注册地址为上海市嘉定区华江路850弄75号1层。
		</p>
		
		
		
		
		<h3>
		引言
		</h3>
		<p>
		"GoGoBear (跑跑太空熊)"应用（以下简称“我们”）尊重并保护所有使用本应用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。
		您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。
		我们根据《中华人民共和国网络安全法》、《信息安全技术 个人信息安全规范》（GB/T 35273-2017）以及其他相关法律法规和技术规范明确了我们收集/使用/对外提供个人信息的原则，进一步阐述了关于您个人信息的相关权利。
		</p>
		<p>
		为了有效保护用户的隐私权和利益，优化用户体验，我们的运营商根据现行法律和政策制定了本个人信息保护政策。本个人信息保护政策将详细说明收集运营商在获取、管理和保护用户个人信息方面的政策和措施。本个人信息保护政策适用于我们的运营商向您提供的所有服务，无论您是通过计算机设备、移动终端还是其他设备获得我们的服务。
		</p>
		<p>
		本个人信息保护政策是您使用我们的服务和各种功能的基本文件。我们希望您在使用我们的服务之前仔细阅读并确保您已经完全理解并接受了本政策的内容，并希望您能够根据自己的理解做出适当的选择。我们力求用通俗易懂的语言表达本个人信息保护政策中涉及的相关术语，并提供进一步解释的链接，以便您更好地理解。如果您在我们更新此个人信息保护政策后继续使用我们的产品和/或服务(我们将及时提醒您更新)，这意味着您同意此个人信息保护政策的内容(包括更新版本)，并同意我们根据此个人信息保护政策收集、使用、保存和共享您的相关信息。
		</p>
		<p>
		在使用/继续使用我们的产品和服务之前，请阅读并充分理解本政策，并在必要时根据本政策的指导方针做出适当的选择。如果您不同意本政策的内容，可能会导致我们的产品和/或服务无法正常运行或达到我们想要达到的服务效果。您应该立即停止访问/使用我们的产品和/或服务。您使用或继续使用我们提供的产品和/或服务的行为意味着您完全理解并同意本《GoGoBear (跑跑太空熊)用户隐私政策》(包括更新版本)的所有内容。
		</p>
		
		
		<h3>
		1. 适用范围
		</h3>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    1.1 在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    1.2 本应用通过合法途径从商业伙伴处取得的用户个人数据。
		</p>
		
		
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    1.3 个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本个人信息保护政策中涉及的个人敏感信息包括：个人身份信息（包括身份证、军官证、护照、驾驶证、户口本）；网络身份识别信息（包括账户名、账户昵称、邮箱地址及与前述有关的密码与密码保护问题和答案）；其他信息（包括通讯录、个人电话号码、手机号码、行程信息、网页浏览记录、精准定位信息、银行账户）；相关身份证明。
		</p>
		
		
		<!--
		
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    1.4 个人信息保护政策涉及的个人信息包括：基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）；个人身份信息（包括身份证、军官证、护照、驾驶证、相关身份证明等）；个人位置信息；网络身份标识信息（包括系统帐号、IP地址、邮箱地址及与前述有关的密码、密保）；通讯录；个人上网记录（包括搜索记录、软件使用记录、点击记录）；个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）。
		</p>
		
		-->
		
		
		<h3>
		2. 用户产生内容的监管
		</h3>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    2.1 用户以各种方式使用GoGoBear (跑跑太空熊)平台服务和数据(包括但不限于发表、宣传介绍、转载、浏览及利用GoGoBear (跑跑太空熊)平台或GoGoBear (跑跑太空熊)平台用户发布内容)的过程中，不得以任何方式利用GoGoBear (跑跑太空熊)平台直接或间接从事违反中国法律、以及社会公德的行为，且用户应当恪守下述承诺：
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    2.1.1 发布、转载或提供的内容符合中国法律、社会公德;
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    2.1.2 不得干扰、损害和侵犯GoGoBear (跑跑太空熊)平台的各种合法权利与利益; 
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    2.1.3 遵守GoGoBear (跑跑太空熊)平台以及与之相关的网络服务的协议、指导原则、管理细则等; 
		</p>
		<h4>
		GoGoBear (跑跑太空熊)平台有权对违反上述承诺的内容予以删除，并提交至相关监管部门。
		</h4>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    2.2 GoGoBear (跑跑太空熊)平台仅为用户发布的内容提供存储空间，GoGoBear (跑跑太空熊)平台不对用户发表、转载的内容提供任何形式的保证：不保证内容满足您的要求，不保证GoGoBear (跑跑太空熊)平台的服务不会中断。因网络状况、通讯线路、第三方网站或管理部门的要求等任何原因而导致您不能正常使用，GoGoBear (跑跑太空熊)平台不承担任何法律责任。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    2.3 用户在GoGoBear (跑跑太空熊)平台发表的内容(包含但不限于GoGoBear (跑跑太空熊)平台目前各产品功能里的内容)仅表明其个人的立场和观点，并不代表GoGoBear (跑跑太空熊)平台的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。GoGoBear (跑跑太空熊)平台不承担任何法律及连带责任。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    2.4 用户在GoGoBear (跑跑太空熊)平台发布侵犯他人知识产权或其他合法权益的内容，GoGoBear (跑跑太空熊)平台有权予以删除，并保留移交司法机关处理的权利。 
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    2.5 个人或单位如认为GoGoBear (跑跑太空熊)平台上存在侵犯自身合法权益的内容，应准备好具有法律效应的证明材料，及时与GoGoBear (跑跑太空熊)平台取得联系，以便GoGoBear (跑跑太空熊)平台迅速做出处理。
		</p>
		
		
		
		
		
		<h3>
		3. 信息使用
		</h3>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.1 您已知悉且同意，在您注册GoGoBear (跑跑太空熊)帐号或使用GoGoBear (跑跑太空熊)提供的服务时，GoGoBear (跑跑太空熊)运营方将记录您提供的相关个人信息，如：密码、手机号码等，上述个人信息是您获得GoGoBear (跑跑太空熊)运营方提供服务的基础。同时，基于优化用户体验之目的，GoGoBear (跑跑太空熊)运营方会获取与提升GoGoBear (跑跑太空熊)服务有关的其他信息，例如当您访问GoGoBear (跑跑太空熊)时，我们可能会收集哪些服务的受欢迎程度、浏览器软件信息等以便优化我们的服务。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.2 在您使用GoGoBear (跑跑太空熊)前，我们会引导您阅读本协议，并在您接受本协议的基础上，获得您的相关个人信息。如果您不同意提供个人信息，您将无法使用GoGoBear (跑跑太空熊)的全部或部分功能和服务，或无法获得更个性化的功能，或无法收到我们的通知等。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.3 GoGoBear (跑跑太空熊)运营方仅会在出现下列情况时收集您的个人信息：
		在您注册GoGoBear (跑跑太空熊)帐号时，我们至少会收集您的帐户昵称、性别、密码、密码保护选项、电子邮箱、手机号码等信息。若您以其他方式关联登录GoGoBear (跑跑太空熊)，我们会向关联第三方请求您的个人信息，对于我们要求但第三方无法提供的个人信息，我们仍可以要求您提供。如果您拒绝提供这些信息，将影响您使用GoGoBear (跑跑太空熊)或GoGoBear (跑跑太空熊)的相关功能。在您注册使用GoGoBear (跑跑太空熊)时，我们可能会对您进行实名验证，如果您拒绝提供有关信息，GoGoBear (跑跑太空熊)运营方将拒绝向您提供服务。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.4 在您使用GoGoBear (跑跑太空熊)运营方提供的搜索服务时，我们会收集您的查询关键字信息、设备信息等，为了提供高效的搜索服务，这些信息有部分会暂时存储在您的本地存储设备之中。在此，您需要注意的是，您的关键词信息无法单独识别您的个人身份，其不属于您的个人信息，因此我们有权以其他的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息互有联系并可以识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本个人信息保护政策对其进行处理与保护。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.5 在您使用GoGoBear (跑跑太空熊)运营方提供的身份认证服务时，我们会收集您的姓名、身份证号、职业、有关身份证明等信息，GoGoBear (跑跑太空熊)运营方对您的这些隐私信息会加以最大程度的保护，如果您不提供这些信息，我们将无法提供相关服务。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.6 当您参加GoGoBear (跑跑太空熊)的有关营销活动时，我们会收集您的姓名、学校名称、通讯地址、联系方式、银行帐号等信息。这些信息是您收到转账或礼品的基础，如果您拒绝提供这些信息，我们将无法向您转账或发放礼品。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.7 基于对您信息的有效保护并且在您同意的基础上，我们会对收集到的您的信息进行去标签化处理，以保护您的信息安全。在此希望您了解并接受在不透露您个人信息的前提下，GoGoBear (跑跑太空熊)运营方有权对您的信息进行分析并予以商业化的利用。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.8 本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.9 本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    3.10 为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
		</p>
		
		
		
		
		
		<h3>
		4. 信息披露
		</h3>
		<h4>
		在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息:
		</h4>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    4.1 经您事先同意，向第三方披露。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    4.2 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    4.3 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    4.4 如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    4.5 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    4.6 其它本应用根据法律、法规或者网站政策认为合适的披露。
		</p>
		<h4>
		此外，您已知悉并同意：在现行法律法规允许的范围内，GoGoBear (跑跑太空熊)运营方可能会将您非敏感的个人信息用于市场营销，使用方式包括但不限于：GoGoBear (跑跑太空熊)运营方会使用您所提供信息在GoGoBear (跑跑太空熊)平台中向您展示或提供广告和促销资料，向您通告或推荐GoGoBear (跑跑太空熊)的服务或产品信息，以及其他此类根据您使用GoGoBear (跑跑太空熊)服务或产品的情况所认为您可能会感兴趣的信息。其中也包括您在采取授权等某动作时选择分享的信息，例如当您新增粉丝和关注、在动态中新增地标、使用GoGoBear (跑跑太空熊)的联络人等。
		</h4>
		<h4>
		您可以向特定的对象发送您的照片、身份信息、位置信息等，也可以基于GoGoBear (跑跑太空熊)的服务向不特定人共享自己的照片、位置信息、身份信息等。当您因为您的共享行为导致您的信息发生泄漏等严重情况时，GoGoBear (跑跑太空熊)运营方不承担相关法律责任。
		</h4>
		<h4>
		随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。
		</h4>
		
		
		
		
		
		<h3>
		5. 信息存储和交换
		</h3>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.1 本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.2 GoGoBear (跑跑太空熊) 会在用户使用应用的过程中获取相关权限，提供给用户更好的体验，并保证用户在授权过程当中知晓授权详情，且用户有足够的权利选择同意或拒绝授权；获取权限后，GoGoBear (跑跑太空熊) 不会将任何通过权限获取到的内容外泄，保证用户在清晰知晓当前情况下进行权限授权操作，保障用户的数据安全。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    5.2.1 获取相机及相册权限，用于发布动态和状态中的图片与视频；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    5.2.2 获取大致和确切地理位置，用于发布动态中用户可添加位置信息；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    5.2.3 获取录制音频和麦克风权限，用于用户之间可发语音消息；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    5.2.4 获取设备识别码和状态，用于识别设备唯一性，保证用户的帐号及隐私安全；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    5.2.5 读取或修改SD卡或其他存储器中的内容，用于用户的图片及消息保存；
		</p>
		
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.3 GoGoBear (跑跑太空熊)运营方会采取合适的安全措施和技术手段存储及保护您的个人信息，以防止丢失、被误用、受到未授权访问或泄漏、被篡改或毁坏。您的个人信息存放在有密码控制的位于中国境内的服务器中，访问均是受到限制的。当您需要跨境信息传输服务时，GoGoBear (跑跑太空熊)运营方会默认您继续使用中国境内的服务器。
		</p>
		
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.4 GoGoBear (跑跑太空熊)运营方会依照个人信息的不同等级存储不同期限，存储期限严格按照法律及相关法规规定，最低期限不少于6个月。
		</p>
		
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.5 根据本条款的规定，我们仅允许有必要知晓这些信息的GoGoBear (跑跑太空熊)运营方员工等第三方访问个人信息，并要求他们履行相应的保密义务。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.6 当您完成GoGoBear (跑跑太空熊)的帐号注册并进行合理和必要的身份验证后，您可以查阅、修改、删除您提交给GoGoBear (跑跑太空熊)运营方的个人信息。一般情况下，您可随时浏览、修改、删除自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的某些初始注册信息及验证信息。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.7 GoGoBear (跑跑太空熊)运营方始终将对您个人信息的保护放在首位，同时，GoGoBear (跑跑太空熊)运营方重视您对个人信息的关注。对于您的个人信息，您可以通过手机客户端途径在线查询、修改、删除您的个人信息。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.8 您有权自主更新或更正您的个人信息，或授权GoGoBear (跑跑太空熊)客服进行信息更新、更正。在您进行信息更新更正之前，我们会首先验证您的身份，其次才能进行信息的更正与更新。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.9 如果您对本个人信息保护政策或其中有关您个人信息的收集、使用、存储、保护等功能存在意见或建议时，您可以通过GoGoBear (跑跑太空熊)功能页面、或GoGoBear (跑跑太空熊)官方管理帐号反馈意见或投诉，GoGoBear (跑跑太空熊)运营方会在收到您意见及建议的第一时间将反馈信息回复给您。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.10 您可以通过关闭GoGoBear (跑跑太空熊)部分功能的方式阻止GoGoBear (跑跑太空熊)运营方获得您的个人信息，关闭部分功能之后，我们将无法向您提供相应的服务。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    5.11 如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
		</p>
		
		
		
		
		
		<h3>
		6. Cookie的使用
		</h3>
		<h4>
		在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息:
		</h4>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    6.1 在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies ，以便您能登录或使用依赖于cookies的本应用平台服务或功能。本应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    6.2 您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    6.3 通过本应用所设cookies所取得的有关信息，将适用本政策。
		</p>
		
		
		
		
		
		<h3>
		7. 信息安全
		</h3>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    7.1 本应用帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    7.2 在使用本应用时，开启设备定位功能并使用GoGoBear (跑跑太空熊)基于位子提供的相关服务（含发布动态，附近功能，个人简介）时，我们可能会手机有关您的GPS位置信息。GPS位置信息属于个人敏感信息，拒绝提供该信息您将无法使用上述基于位置提供的相关服务，但不影响您正常使用GoGoBear (跑跑太空熊)的其他功能.
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    7.3 GoGoBear (跑跑太空熊)运营方将尽一切合理努力保护其获得的用户个人信息，并由专门的数据安全部门对个人信息进行保护。为防止用户个人信息在意外的、未经授权的情况下被非法访问、复制、修改、传送、遗失、破坏、处理或使用，GoGoBear (跑跑太空熊)运营方已经并将继续采取以下措施保护您的个人信息.
		</p>
		
		<h4>
		&nbsp;&nbsp;&nbsp;&nbsp;    GoGoBear (跑跑太空熊)运营方采取的安全措施
		</h4>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.1 我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.2 通过采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.3 在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、加密脱敏等多种数据脱敏技术增强个人信息在使用中安全性。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.4 设立严格的数据使用和访问制度，采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.5 采取专门的数据和技术安全审计，设立日志审计和行为审计多项措施。
		</p>
		
		<h4>
		&nbsp;&nbsp;&nbsp;&nbsp;    保护个人信息采取的其他安全措施
		</h4>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.6 通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.7 建立数据安全专项部门，负责安全应急响应组织来推进和保障个人信息安全。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.8 加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
		</p>
		
		<h4>
		&nbsp;&nbsp;&nbsp;&nbsp;    个人信息安全事件的通知
		</h4>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.9 如发生个人信息引发的安全事件，GoGoBear (跑跑太空熊)运营方将第一事件向相应主管机关报备，并即时进行问题排查，开展应急措施。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    7.3.10 通过与全量用户发送通知提醒更改密码。还可能通过电话、短信等各种方式触达用户知晓，在GoGoBear (跑跑太空熊)公共运营平台运营宣传，制止数据泄露。
		</p>
		
		
		<h4>
		尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但GoGoBear (跑跑太空熊)运营方仍然无法保证您的个人信息通过不安全途径进行交流时的安全性。因此，用户个人应采取积极措施保证个人信息的安全，如：定期修改帐号密码，不将自己的帐号密码等个人信息透露给他人。
		</h4>
		<h4>
		您知悉：GoGoBear (跑跑太空熊)运营方提供的个人信息保护措施仅适用于GoGoBear (跑跑太空熊)平台，一旦您离开GoGoBear (跑跑太空熊)，浏览或使用其他网站、服务及内容资源，GoGoBear (跑跑太空熊)运营方即没有能力及义务保护您在GoGoBear (跑跑太空熊)以外的网站提交的任何个人信息，无论您登录或浏览上述网站是否基于GoGoBear (跑跑太空熊)的链接或引导。
		</h4>
		<h4>
		网络环境中始终存在各种信息泄漏的风险，当出现意外事件、不可抗力等情形导致您的信息出现泄漏时，GoGoBear (跑跑太空熊)运营方将极力控制局面，及时告知您事件起因、GoGoBear (跑跑太空熊)运营方采取的安全措施、您可以主动采取的安全措施等相关情况。
		</h4>
		
		
		
		<h3>
		8. 本隐私政策的更改
		</h3>
		
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    8.1 如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    8.2 本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    8.3 请勿披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    8.4 为给您提供更好的服务以及随着我们业务的发展，本个人信息保护政策也会随之更新。但未经您明确同意，我们不会削减您依据当前生效个人信息保护政策所应享有的权利。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    8.5 我们会通过在网站、移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，以便及时了解最新的个人信息保护政策。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    8.6 对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信、私信或在浏览页面做特别提示等方式，说明个人信息保护政策的具体变更内容）。本政策所指的重大变更包括但不限于：
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    8.6.1 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    8.6.2 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    8.6.3 个人信息共享、转让或公开披露的主要对象发生变化；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    8.6.4 您参与个人信息处理方面的权利及其行使方式发生重大变化；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    8.6.5 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    8.6.6 个人信息安全影响评估报告表明存在高风险时。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;    8.7 本公司对此协议保留最终解释权。
		</p>
		
		<h3>
		9. 其他
		</h3>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;   9.1 如您对个人信息保护问题有任何投诉、建议、疑问，或对于本个人信息保护政策或相关事宜有任何问题，您可随时通过GoGoBear (跑跑太空熊)官方服务帐号或下方电子邮件联系我们，我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内答复：【maniya_tech@163.com】。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;   9.2 当您因为本政策的实施与GoGoBear (跑跑太空熊)运营方产生任何纠纷时，双方应首先协商友好解决；若不能协商解决，双方有权依照注册协议向有管辖权的法院提起诉讼。
		</p>
		<p>
		&nbsp;&nbsp;&nbsp;&nbsp;   9.3 如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。
		</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Privacy',
		
	}
</script>

<style scoped="scoped">
	.Privacy{
		background-color: #fff;
		box-sizing: border-box;
		padding: 0 0 0.3rem 0;
	}
</style>

<template>
	<div class="coo">
		<img src="../assets/coo/bg.png" class="bg" />
		<div class="num">
			<div class="">200+</div>
			<div class="">全国点位</div>
		</div>
		<div class="mode">
			<div class="content">
				<img src="../assets/icon_title.png" class="t_icon" />
				<div class="mode_title">
					合作方式
				</div>
				<div class="mode_title_en">
					COOPERATION MODE
				</div>
				<div class="mode_sys" style="margin-top: 0.05rem;">
					我们有2种合作方式，分别为"购买"与"租借"。了解详情请拨打 400-829-6068
				</div>
				<div class="mode_sys">
					一年的加盟费合计约50000元(多点位数大力优惠)：设备分"租借"和"购买"2种模式自由选择，起订量为1个点位，每个点位缴纳加盟费约5000元+约3✖15888元内置记账器购置费(推荐一个点位三个设备)合伙人享有约80%的营业额分成。
				</div>
			</div>
		</div>
		<div class="table_title">收益预算表</div>
		<div class="table">
			<div class="content2">
				
				<div class="row">
					<div class="li1">每个点位</div>
					<div class="li2">日均20单</div>
					<div class="li2">日均25单</div>
					<div class="li2">日均30单</div>
				</div>
				<div class="lists">
				<div class="row">
					<div class="li1">平均客单价</div>
					<div class="li2 CD6288">25</div>
					<div class="li2 CD6288">25</div>
					<div class="li2 CD6288">25</div>
				</div>
				<div class="row">
					<div class="li1">评价享受额</div>
					<div class="li2 CD6288">18.3万</div>
					<div class="li2 CD6288">22.8万</div>
					<div class="li2 CD6288">27.4万</div>
				</div>
				<div class="row">
					<div class="li1">一年分成利益</div>
					<div class="li2 CD6288">14.6万</div>
					<div class="li2 CD6288">18.3万</div>
					<div class="li2 CD6288">21.9万</div>
				</div>
				<div class="row">
					<div class="li1">三年分成利益</div>
					<div class="li2 CD6288">43.8万</div>
					<div class="li2 CD6288">54.8万</div>
					<div class="li2 CD6288">65.7万</div>
				</div>
				<div class="row">
					<div class="li1">一年回报率</div>
					<div class="li2 CD6288">2.9倍</div>
					<div class="li2 CD6288">3.7倍</div>
					<div class="li2 CD6288">4.4倍</div>
				</div>
				<div class="row">
					<div class="li1">三年回报率</div>
					<div class="li2 CD6288">8.8倍</div>
					<div class="li2 CD6288">11倍</div>
					<div class="li2 CD6288">13.1倍</div>
				</div>
				</div>
			</div>
		</div>
		<div class="brands">
			<div class="content3">
				<img src="../assets/icon_title.png" class="t_icon2" />
				<div class="mode_title">
					合作品牌与授权
				</div>
				<div class="mode_title_en">
					CO-BRANDS
				</div>
				<div class="mode_sys tac">
					以下合作品牌与授权仅作为部分展示
				</div>
			</div>
		</div>
		<div class="honor">
			<el-carousel :interval="4000" type="card" height="1.4rem">
			    <el-carousel-item >
			      <img src="../assets/honor1.png" class="honor_pic"/>
			    </el-carousel-item>
					<el-carousel-item >
					  <img src="../assets/honor2.png" class="honor_pic"/>
					</el-carousel-item>
					<el-carousel-item >
					  <img src="../assets/honor3.png" class="honor_pic"/>
					</el-carousel-item>
					<el-carousel-item >
					  <img src="../assets/honor4.png" class="honor_pic"/>
					</el-carousel-item>
					<el-carousel-item >
					  <img src="../assets/honor5.png" class="honor_pic"/>
					</el-carousel-item>
			  </el-carousel>
		</div>
		<div class="co-b">
			<div class="content4">
				<img src="../assets/icon_title.png" class="t_icon3" />
				<div class="mode_title">
					加盟门店实拍
				</div>
				<div class="mode_title_en">
					CO-BRANDS
				</div>
				<div class="mode_sys" style="margin-top: 0.05rem;">
					以下均为加盟点位实拍照片(照片仅作部分展示)
				</div>
				<div class="place_img">
					<Swiper>
						<slide>
							<img src="../assets/hot1.png" class="p_img"/>
						</slide>
						<slide>
							<img src="../assets/hot2.png" class="p_img"/>
						</slide>
						<slide>
							<img src="../assets/hot3.png" class="p_img"/>
						</slide>
						<slide>
							<img src="../assets/hot4.png" class="p_img"/>
						</slide>
					</Swiper>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Swiper, Slide } from 'vue-swiper-component';
	export default{
		name:'cooperation',
		components: {
			Swiper,
		  Slide
		},
	}
</script>

<style scoped="scoped">
	.coo{
		position: relative;
	}
	.bg{
		width: 100%;
	}
	.num{
		position: absolute;
		top: 3rem;
		left: 0.33rem;
		width: 0.74rem;
		height: 0.74rem;
		box-sizing: border-box;
		padding: 0.05rem;
	}
	.num div{
		color: #fff;
		font-size: 0.16rem;
	}
	.num div:first-child{
		font-size: 0.28rem;
	}
	.mode{
		position: absolute;
		top: 4.26rem;
		left: 0.25rem;
		width: 3.2rem;
		height: 2.98rem;
	}
	.content{
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 0.35rem 0.3rem;
		position: relative;
	}
	.mode_title{
		font-size: 0.26rem;
		color: #5454b0;
		font-weight: bold;
	}
	.mode_title_en{
		font-size: 0.15rem;
		color: #999;
	}
	.mode_title_en:after{
		content: '';
		display: block;
		width: 20px;
		height: 1px;
		margin: 0 auto;
		margin-top: 10px;
		background-color: #333;
	}
	.t_icon{
		position: absolute;
		top: 0.4rem;
		left: 0.5rem;
		width: 0.45rem;
	}
	.mode_sys{
		font-size: 0.13rem;
		color: #999;
		text-align: left;
	}
	.table{
		position: absolute;
		top: 7.86rem;
		left: 0.25rem;
		width: 3.2rem;
		height: 2rem;
	}
	.content2{
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 0.05rem 0.2rem 0.2rem;
		position: relative;
		overflow-y: auto;
		
	}
	@media screen and (max-width: 375px){
		/* .content2{
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding: 0.05rem 0.2rem 0.2rem;
			position: relative;
			overflow-y: auto;
		} */
		.lists{
			width: 100%;
			height: 75%;
			overflow-y: auto;
		}
	}
	.table_title{
		position: absolute;
		top: 7.46rem;
		left: 1.3rem;
		width: 1.1rem;
		height: 0.35rem;
		line-height: 0.35rem;
		color: #cd6288;
		font-size: 0.15rem;
		font-weight: bold;
	}
	.row{
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		line-height: 0.24rem;
	}
	.li1{
		width: 0.8rem;
		font-size: 0.13rem;
	}
	.li2{
		width: 0.65rem;
	}
	.CD6288{
		color: #CD6288;
	}
	.brands{
		position: absolute;
		top: 10.2rem;
		left: 0.27rem;
		width: 3.25rem;
		height: 1.3rem;
	}
	.content3{
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 0.15rem 0.35rem;
		position: relative;
	}
	.t_icon2{
		position: absolute;
		top: 0.15rem;
		left: 0.25rem;
		width: 0.45rem;
	}
	.tac{
		text-align: center;
		font-size: 0.13rem;
		color: #000;
		margin-top: 0.05rem;
	}
	.honor{
		position: absolute;
		top: 15.2rem;
		left: 0.27rem;
		width: 3.25rem;
		height: 1.38rem;
		overflow: hidden;
	}
	.honor_pic{
		height: 100%;
	}
	.co-b{
		position: absolute;
		top: 16.67rem;
		left: 0.27rem;
		width: 3.18rem;
		height: 3.8rem;
		overflow: hidden;
	}
	.content4{
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 0.3rem 0.33rem;
		position: relative;
	}
	.t_icon3{
		position: absolute;
		top: 0.3rem;
		left: 0.3rem;
		width: 0.45rem;
	}
	.place_img{
		width: 100%;
		height: 2.05rem;
		border-radius: 0.1rem;
		margin-top: 0.05rem;
	}
	.p_img{
		width: 100%;
		border-radius: 0.1rem;
	}
</style>

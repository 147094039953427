<template>
	<div class="terms">
		<div style="width:95%;margin:0 auto">
		
		
		
		<h3>
		尊敬的用户您好：在您使用本服务之前，请您认真阅读本用户协议，更好的了解我们所提供的服务以及您享有的权利义务。您开始使用时，即表示您已经了解并确认接受了本文件中的全部条款，包括我们对本服务条款随时做的任何修改。
		</h3>
		
		
		<h3>
		一、协议的效力
		</h3>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		本协议内容包括协议正文及所有已经发布或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止使用本平台服务。
		</p>
		
		
		<h3>
		二、用户行为规范
		</h3>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		用户同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		发布或以其它方式传送含有下列内容之一的信息：
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		反对宪法所确定的基本原则的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		损害国家荣誉和利益的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		煽动民族仇恨、民族歧视、破坏民族团结的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		破坏国家宗教政策，宣扬邪教和封建迷信的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		散布谣言，扰乱社会秩序，破坏社会稳定的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		侮辱或者诽谤他人，侵害他人合法权利的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		含有当地法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		含有不适合在本平台展示的内容；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		以任何方式危害他人的合法权益；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		冒充其他任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		将依据任何法律或合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）知悉但无权传送之任何内容加以发布、发送电子邮件或以其它方式传送；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		将侵害他人著作权、专利权、商标权、商业秘密、或其它专属权利（以下简称“专属权利”）之内容加以发布或以其它方式传送；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		将任何广告信函、促销资料、“垃圾邮件”、““滥发信件”、“连锁信件”、“直销”或其它任何形式的劝诱资料加以发布、发送或以其它方式传送；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能之计算机病毒（包括但不限于木马程序（trojan horses）、蠕虫（worms）、定时炸弹、删除蝇（cancelbots）（以下简称“病毒”）或其它计算机代码、档案和程序之任何资料，加以发布、发送或以其它方式传送；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		跟踪、人肉搜索或以其它方式骚扰他人；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		故意或非故意地违反任何适用的当地、国家法律，以及任何具有法律效力的规则；
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		未经合法授权而截获、篡改、收集、储存或删除他人个人信息、站内邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。
		</p>
		
		
		
		<h3>
		三、知识产权
		</h3>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		本平台所有设计图样以及其他图样、产品及服务名称。任何人不得使用、复制或用作其他用途。未经我们许可，任何单位和个人不得私自复制、传播、展示、镜像、上载、下载、使用，或者从事任何其他侵犯我们知识产权的行为。否则，我们将追究相关法律责任。
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		我们鼓励用户充分利用平台自由地张贴和共享自己的信息，但这些内容必须位于公共领域内，或者用户拥有这些内容的使用权。同时，用户对于其创作并在本平台上发布的合法内容依法享有著作权及其相关权利。
		</p>
		
		
		
		
		<h3>
		四、免责声明
		</h3>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		互联网是一个开放平台，用户将照片等个人资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。用户明确同意其使用本服务所存在的风险将完全由其自己承担；因其使用本服务而产生的一切后果也由其自己承担，我们对用户不承担任何责任。
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		对于用户上传的照片、资料、证件等，已采用相关措施并已尽合理努力进行审核，但不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的会员负责。
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		尽管已采取相应的技术保障措施 ，但用户仍有可能收到各类的广告信或其他不以招聘/应聘为目的邮件或其它方式传送的任何内容，本平台不承担责任。
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		对于各种广告信息、链接、资讯等，不保证其内容的正确性、合法性或可靠性，相关责任由广告商承担；用户通过本服务与广告商进行任何形式的通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及达成的其它任何相关条款、条件、保证或声明，完全为用户与广告商之间之行为，与本平台无关。用户因前述任何交易或前述广告商而遭受的任何性质的损失或损害，本平台不承担任何责任。
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		本平台不保证其提供的服务一定能满足用户的要求和期望，也不保证服务不会中断，对服务的及时性、安全性、准确性也都不作保证。对于因不可抗力或无法控制的原因造成的网络服务中断或其他缺陷，不承担任何责任。我们不对用户所发布信息的删除或储存失败承担责任。我们有权判断用户的行为是否符合本网站使用协议条款之规定，如果我们认为用户违背了协议条款的规定，我们有终止向其提供服务的权利。
		</p>
		<p>&nbsp;&nbsp;&nbsp;&nbsp;    
		本平台保留变更、中断或终止部分网络服务的权利。保留根据实际情况随时调整平台提供的服务种类、形式的权利。本平台不承担因业务调整给用户造成的损失。本平台仅提供相关服务，除此之外与本服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。
		</p>
		
		
		</div>
	</div>
</template>

<script>
	export default {
		name: 'terms'
	}
</script>

<style scoped="scoped">
	.terms{
		background-color: #fff;
		box-sizing: border-box;
		padding: 0 0 0.3rem 0;
	}
</style>

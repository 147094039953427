<template>
	<div class="art">
		<div class="box">
			<div class="title">
				跑跑太空熊引领智能新时代，手机遥控自动充电，深得人心
			</div>
			<p>
				如今跑跑太空熊共享儿童汽车已经看准儿童的娱乐市场，从儿童玩具车开始，主要是为0~12岁的儿童提供服务。目前主要是考虑和社区物业一起合作，会在一些主体的空间创建一些不需要人看管的玩具车棚，这就能够有效提供分时租赁的服务，让大家在玩耍时比较开心。
			</p>
			<img src="../../assets/news/p24.jpeg" class="img" />
			<p>据说这些车棚也会拥有自动充电桩，可以遮风避雨，当大家在享用玩具车的时候，只需要通过手机就能够遥控，可以有效满足年龄比较小的孩子不会自己开车的需求，而且还能够有效扩大用户的需求，可以把物理遥控器丢失的几率逐渐的降低到0，一般在使用之后只需要退回就马上进入到自动充电的阶段，所以就开始得到了众多人的认可。</p>
			<img src="../../assets/news/p25.jpeg" class="img" />
			<p>
				跑跑太空熊共享儿童汽车之所以会得到大家的认可，是因为选择新型锂动力电池早就已经抛弃传统的铅酸电池，能够带来比较长的续航里程，所以给大家留下了较好的印象。如今虽然只不过是刚刚开始的阶段，但是人们也会看到在很多的小区都已经开始尝试，相信在接下来就会在游乐园景区又或者是超市进行投放，会迅速的在整个市场上进行普及。
			</p>
			<img src="../../assets/news/p26.jpeg" class="img" />
			<p>除了玩具车会提供一些分时租赁的服务之外，也会为大家带来自主品牌的智能汽车，不仅可以自动的充电，而且还可以手机遥控，更加可以扫码租赁。与此同时还特意添加定位的功能，让大家在操作的时候更加的安全，随时都可以保证孩子的行程安全。另外家长在手机上也可以设置一些对应的操作，在遇到一些紧急情况时，还能够有效远程刹车，可以减少意外事故，所以能够体现出更多个性化的特点。</p>
			<p>相信在未来的一段时间内，一定会在整个市场上得到更多人的认可。主要是有效结合智能技术，对于当前的玩具来做出智能化的改造，从传统的配送模式，然后朝着创新的商业模式转变，公司也会逐渐的拓展后期的发展，并且把服务还有产品推向整个市场。</p>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'art8',
		
	}
</script>

<style>
	.art{
		background-color: #fff;
		box-sizing: border-box;
		padding: 0.1rem 0 0.2rem 0;
	}
	.box{
		width: 90%;
		margin: 0 auto;
	}
	.title{
		font-weight: bold;
		font-size: 0.18rem;
		text-align: left;
	}
	p{
		text-align: left;
		margin-top: 0.2rem;
		line-height: 0.25rem;
	}
	.img{
		width: 90%;
		margin: 0 auto;
		margin-top: 0.2rem;
	}
</style>

<template>
	<div class="art">
		<div class="box">
			<div class="title">
				共享童车未来前景大家怎么看？
			</div>
			<p>
				跑跑太空熊智能共享童车未来的市场前景和发展趋势！之前没有讨论过的智能共享童车，逐渐成为一股新生力量，进入大众视野。
			</p>
			<img src="../../assets/news/p23.jpg" class="img" />
			<p>共享的本质是自助租赁。与传统租赁业务相比，有两大优势：一是随时随地；第二，自助贷款。这两点可以节省交易时间和成本，提高自助租赁物业的使用周转率。</p>
			<img src="../../assets/news/p10.jpg" class="img" />
			<p>
				跑跑太空熊智能共享童车，以独特的眼光进入儿童消费市场，推出“儿童交通工具”智能共享童车，为家长解决带孩子外出的痛点，采用扶持的形式，放在景区、商场、儿童公园等儿童经常出没的地方，为家长减轻负担，为孩子打造一辆舒适的流动车，满足大众需求。同时，也提升了整体形象，为用户创造了增值服务。
			</p>
			<img src="../../assets/news/p3.jpg" class="img" />
			<p>我国1-7岁儿童人口已超过1亿，儿童消费在家庭消费中占比最高。我们有世界上最大的儿童消费群体。随着收入水平的提高和消费结构的升级，对新业态、新消费的需求不断扩大。2岁以上儿童使用的新一代共享婴儿车很受欢迎。因此，具有提升服务、性价比高、满足实际使用需求等优势的共享婴儿车，也将推动用户规模的快速增长。</p>
			<p>拥有庞大的人口基数，智能共享童车有着巨大的发展空间。一项创新一旦有效满足需求，解决痛点，就可能迅速点燃市场热情，走上快速发展的轨道。智能共享童车很好地适应了这一需求，将受到人们的欢迎。</p>
			<p>从婴儿车到智能共享童车，这一方面代表着出行便利性的不断提升，另一方面也说明适合的出行工具对于有需要的人来说越来越重要，一辆共享婴儿车就能满足宝宝代步的交通需求。</p>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'art4',
		
	}
</script>

<style>
	.art{
		background-color: #fff;
		box-sizing: border-box;
		padding: 0.1rem 0 0.2rem 0;
	}
	.box{
		width: 90%;
		margin: 0 auto;
	}
	.title{
		font-weight: bold;
		font-size: 0.18rem;
		text-align: left;
	}
	p{
		text-align: left;
		margin-top: 0.2rem;
		line-height: 0.25rem;
	}
	.img{
		width: 90%;
		margin: 0 auto;
		margin-top: 0.2rem;
	}
</style>

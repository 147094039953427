<template>
	<div class="art">
		<div class="box">
			<div class="title">
				跑跑太空熊共享儿童智能玩具车的新玩法
			</div>
			<p>
				在重庆，大规模出现的“跑跑太空熊”是由上海一家科技公司推出的。公司成立于2017年6月，已完成数百万天使融资。融资主要用于市场拓展、车辆研发和量产。
			</p>
			<img src="../../assets/news/p13.jpg" class="img" />
			<p>共享童车的收入来源主要有三个板块：一是租赁收入，每15分钟收费3元，目前占比最大；二是童车的广告收入，比如玩早教节目、动漫、益智游戏等。三是增值收益，包括预收费和押金、与商场合作组织活动、提供大数据服务等。</p>
			<p>
				据悉，在商业模式方面，公司采用自营合作伙伴/渠道商业模式。在一二线城市和一些三线城市，跑跑太空熊会自费投资车辆，收益可由跑跑太空熊和商场分配；对于三线及以下城市，他们将与渠道经销商和城市合作伙伴合作，渠道经销商和合作伙伴将购买购物车。运营计划和培训将由跑跑太空熊，提供，收入将由跑跑太空熊，代理商和企业分享。
			</p>
			<img src="../../assets/news/p11.jpg" class="img" />
			<p>截至2019年，跑跑太空熊已经在全国范围内布局了27个城市，并将在4月至5月期间实现单月盈利，未来还将继续盈利。公司正在加快共享童车的研发，二代车将在家里增加一个电子显示屏，主要用于室内导航。未来除了购物中心，还会有更多的场景，比如儿童医院、公园、景点等。</p>
			<p>目前，共享经济、节能环保的理念已逐渐被大众接受，但是，婴幼儿的共享汽车是否和成人一样好呢？</p>
			<img src="../../assets/news/p10.jpg" class="img" />
			<p>互联网分析师认为，虽然随着“二孩政策”的放开，婴幼儿已经成为重要的市场增长点，但对于共享经济来说，没有任何商品值得与婴幼儿分享。婴幼儿的共享类别必须满足大众的需求，满足用户的心理需求，所以大众愿意为此买单。</p>
			<p>据业内分析人士介绍，在分享婴儿出行方面，一些特定场景有一定的出行需求，比如公园、商场、医院、广场等。但是这种需求的程度还没有达到对受众饥渴的程度，也就意味着用户的痛点并不明显。这样一来，有一定的需求，但不广泛，这样的共享产品很难形成爆款，也很难像自行车共享那样快速聚集用户，从而实现更大规模的资本运营和市场拓展。</p>
			<p>目前，在儿童共享经济领域，最成功的是知识共享领域，比如热门的共享儿童汽车品牌如跑跑太空熊等。通过产品，把孩子和外教联系起来，根据学生的喜好选择玩具，实现全国通用。</p>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'art5',
		
	}
</script>

<style>
	.art{
		background-color: #fff;
		box-sizing: border-box;
		padding: 0.1rem 0 0.2rem 0;
	}
	.box{
		width: 90%;
		margin: 0 auto;
	}
	.title{
		font-weight: bold;
		font-size: 0.18rem;
		text-align: left;
	}
	p{
		text-align: left;
		margin-top: 0.2rem;
		line-height: 0.25rem;
	}
	.img{
		width: 90%;
		margin: 0 auto;
		margin-top: 0.2rem;
	}
</style>

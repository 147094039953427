<template>
	<div class="Contact">
		<div class="con_box">
			<div class="flex">
				<div class="fl_l">联系人</div>
				<input v-model="name" class="input" type="text"/>
			</div>
			<div class="err" v-if="nameisNull">联系人姓名不能为空！</div>
			<div class="flex">
				<div class="fl_l">联系电话</div>
				<input v-model="tel" class="input" type="tel"/>
			</div>
			<div class="err" v-if="telisNull">联系人电话不能为空！</div>
			<div class="err" v-if="telisErr">联系人电话格式有误！</div>
			<div class="flex" >
				<div class="fl_2" @click="showPopup" >{{Aname}}</div>
					<van-popup
					  v-model="show"
					  position="bottom"
					  :style="{ height: '50%' }">
					  <van-area :area-list="areaList" :columns-num="3" ref="myArea" title="请选择地区" @change="onChange" @confirm="onConfirm" @cancel="onCancel"/>
					</van-popup>
			</div>
			<div class="err" v-if="addressisNull">联系人地址不能为空！</div>
			<div class="flex">
				<div class="fl_l">加盟电话</div>
				<div @click="callPhone" class="phone">400-229-6068</div>	
			</div>
			
			
			<div class="submit" v-if="submited" @click="submit">提交</div>
			<div class="submited" v-else @click="resubmit">提交</div>
			<div v-if="smAl">您已经提交过了！请勿重复提交</div>
		</div>
		
	</div>
</template>

<script>
	import { areaList } from '@vant/area-data';
	export default{
		name: 'Contact',	
		data(){
			return{
				name: '',
				tel: '',
				city: '',
				areaList,
				show:false,
				carmodel: [],
				Aname: '所在城市',
				nameisNull: false,
				telisNull: false,
				addressisNull: false,
				telisErr: false,
				submited: true
			}
		},
		methods:{
			showPopup() {
			  this.show = true;		      
			},
			onChange(picker, index, value){
			  let val = picker.getValues();
			  console.log(val)//查看打印
			  let areaName = []
				let Name = ''
			  for (var i = 0; i < val.length; i++) {
			    areaName.push(val[i].name)
					Name = Name + val[i].name + " ";
			  }
			  this.carmodel = areaName
				this.Aname = Name
			},
			//确定选择城市
			onConfirm(val){
				let areaName = []
				let Name = ''
				for (var i = 0; i < val.length; i++) {
				  areaName.push(val[i].name)
					Name = Name + val[i].name + " ";
				}
				this.carmodel = areaName
				this.Aname = Name
				this.show = false//关闭弹框
			},
			//取消选中城市
			onCancel(){
				this.show = false
				this.$refs.myArea.reset()// 重置城市列表
			},
			callPhone(){
				window.location.href = 'tel://4008296068'
			},
			submit(){
				let phonereg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(18[0-9]{1})|(17[0-9]{1})|(19[0-9]{1}))+\d{8})$/;
				if(this.name == ''){
					this.nameisNull = true
				}else if(this.tel == ''){
					this.telisNull = true
				}else if(this.carmodel.length == 0){
					this.addressisNull = true
				}else if(!phonereg.test(this.tel)){
					this.telisErr = true
				}else{
					console.log('ok')
					this.$axios.post('/collaborate_web',{
						'name': this.name,
						'phone': this.tel,
						'city': this.carmodel
					}).then(res => {
						console.log(res)
						
						if(res.data.code == 1){
							this.submited = false
							alert('提交成功！')
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			resubmit(){
				alert('您已经提交过了！请勿重复提交！')
			}
		}
	}
</script>

<style scoped="scoped">
	.w90{
		width: 90%;
		margin: 0 auto;
	}
	.con_box{
		box-sizing: border-box;
		padding: 0.2rem;
	}
	.flex{
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #fff;
		height: 0.4rem;
		line-height: 0.4rem;
		margin-top: 0.2rem;
		box-sizing: border-box;
		padding: 0.1rem;
		border-radius: 0.05rem;
	}
	.input{
		border: none;
		flex: 1;
		margin-left: 0.1rem;
		outline: none;
	}
	.fl_l{
		width: 1rem;
		text-align: left;
	}
	.fl_2{
		width: 90%;
		text-align: left;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.submit{
		width: 90%;
		margin: 0.3rem auto;
		line-height: 0.4rem;
		height: 0.4rem;
		background-color: palevioletred;
		color: #fff;
		border-radius: 0.05rem;
		font-weight: bold;
	}
	.submited{
		width: 90%;
		margin: 0.3rem auto;
		line-height: 0.4rem;
		height: 0.4rem;
		background-color: gray;
		color: #fff;
		border-radius: 0.05rem;
		font-weight: bold;
	}
	.phone{
		color: #528DBF;
	}
	.err{
		color: red;
		text-align: left;
		margin-left: 0.05rem;
	}
</style>

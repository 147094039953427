<template>
	<div class="headquarters">
		<div class="nav_banner">
			<Swiper>
			  <Slide>
					<img src="../assets/hdq/hdq5.png" class="hdq_pic"/>
				</Slide>
				<Slide>
					<img src="../assets/hdq/hdq2.png" class="hdq_pic"/>
				</Slide>
				<Slide>
					<img src="../assets/hdq/hdq3.png" class="hdq_pic"/>
				</Slide>
				<Slide>
					<img src="../assets/hdq/hdq4.png" class="hdq_pic"/>
				</Slide>
				<Slide>
					<img src="../assets/hdq/hdq6.png" class="hdq_pic"/>
				</Slide>
				<Slide>
					<img src="../assets/hdq/hdq7.png" class="hdq_pic"/>
				</Slide>
				<Slide>
					<img src="../assets/hdq/hdq8.png" class="hdq_pic"/>
				</Slide>
			</Swiper>
		</div>
		<div class="hd_content">
			<img src="../assets/hdq/bg.jpg" class="bg"/>
			<div class="content">
				<img src="../assets/hdq/hdq_icon.png" class="t_icon">
				<div class="h_title">
					上海马尼亚
				</div>
				<div class="h_title">
					娱乐咨询管理有限公司
				</div>
				<div class="h_title_en">
					MANIA TECHNOLOGY
				</div>
				<div class="h_int">
					跑跑太空熊是上海马尼亚娱乐咨询管理有限公司2020年推出儿童共享童车品牌。 延承多年从事儿童游乐，玩具行业始终坚持对产品严谨认真的态度。加入企业文化传承，结合多年来运用管理体系。 致力于打造当下优质的物联网儿童游艺产品
					<img src="../assets/icon_title.png" class="h_i_icon"/>
				</div>
				<div class="appeal">
					<div class="appeal_title">旗下品牌1-跑跑太空熊</div>
					<div class="appeal_title_en">BRAND APPEAL</div>
					<p>1.跑跑太空熊 是多元化集团借由一个品牌搭建与投资者、消费者之间的桥梁。让全新的无人共享童车进入各个幸福美满家庭的生活中。 身为行业的领导者，我们清楚舞台对品牌的重要性。</p>
					<p>2.我们相信快乐的体验，安全卫生的产品要从行业的领导者发起，所以创造三赢的循环就是集团的目标。让新兴的品牌成长，消费者更有意愿体验的产品，帮助更加有实力的投资者扩张市场，彼此串联来相互提升。</p>
				</div>
				<div class="appeal2">
					<div class="appeal_title">旗下品牌2-跑跑太空猫</div>
					<div class="appeal_title_en">BRAND APPEAL</div>
					<div class="open">正在打造</div>
					<div class="more">后续将打造更多优质品牌</div>
					<div class="more">让我们拭目以待吧！</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Swiper, Slide } from 'vue-swiper-component';
	export default {
	  name: 'headquarters',
	  components: {
			Swiper,
	    Slide
	  },
		data(){
			return {
				
				
			}
		},
		methods:{
			
		},
		mounted(){
	
		}
	}
</script>

<style scoped="scoped">
	.nav_banner{
		width: 100%;
		height: 1.85rem;
	}
	.hdq_pic{
		width: 100%;
	}
	.hd_content{
		width: 100%;
		position: relative;
	}
	.bg{
		width: 100%;
	}
	.content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0.3rem;
	}
	.h_title{
		font-size: 0.26rem;
		color: #fffa5a;
		font-weight: bold;
	}
	.h_title_en{
		font-size: 0.15rem;
		color: #d4e6ff;
	}
	.h_title_en::after{
		content: '';
		display: block;
		width: 20px;
		height: 1px;
		margin: 0 auto;
		margin-top: 10px;
		background-color: #d4e6ff;
	}
	.t_icon{
		position: absolute;
		top: 0.15rem;
		left: 0.4rem;
		width: 0.8rem;
	}
	.h_int{
		margin-top: 0.05rem;
		font-size: 0.13rem;
		color: #fff;
		text-align: left;
		position: relative;
	}
	.h_i_icon{
		position: absolute;
		right: -0.2rem;
		bottom: -0.2rem;
		width: 0.5rem;
	}
	.appeal{
		/* margin-top: 0.25rem; */
		height: 3.8rem;
		text-align: left;
		box-sizing: border-box;
		padding: 0.35rem 0.4rem;
		overflow-y: auto;
	}
	.appeal::-webkit-scrollbar{width:0;}
	@media screen and (min-width: 375px){
		.appeal{
			margin-top: 0.25rem;
			height: 3.8rem;
			text-align: left;
			box-sizing: border-box;
			padding: 0.35rem 0.4rem;
			overflow-y: auto;
		}
	}
	.appeal_title{
		font-size: 0.17rem;
		color: #000000;
	}
	.appeal_title_en{
		font-size: 0.13rem;
		color: #979797;
	}
	.appeal_title_en::after{
		content: '';
		display: block;
		width: 20px;
		height: 1px;
		margin-top: 10px;
		background-color: #000;
	}
	.appeal p{
		font-size: 0.13rem;
		color: #000;
		margin-top: 0.1rem;
	}
	.appeal2{
		margin-top: 0.45rem;
		height: 3.5rem;
		text-align: left;
		box-sizing: border-box;
		padding: 0.35rem 0.4rem;
		/* overflow-y: auto; */
	}
	.open{
		font-size: 0.26rem;
		font-weight: bold;
		color: #333;
		height: 1.5rem;
		line-height: 1.5rem;
		text-align: center;
	}
	.more{
		text-align: center;
		font-size: 0.13rem;
		color: #333;
	}
</style>
